import React from 'react'
import Layout from '../components/layout'

export default function Covid19 () {
  const [videoVisible, setVideoVisible] = React.useState(false)

  return (
    <Layout legacy>
      <section className='nm'>
        <div className='row justify-center'>
          <div className='col text-center w75'>
            <div className="inner">
              <h1>COVID-19</h1>
              <p>Trexity keeps a close eye on provincial guidelines to ensure we follow every possible measure. See how contactless delivery works and what we’re doing to keep everyone safe.</p>
              <a
                className='button-link button-link-brand'
                onClick={() => { setVideoVisible(!videoVisible) }}
              >
                {!videoVisible ? 'Watch what we’re doing' : 'Close video'}
              </a>
            </div>
          </div>
        </div>
      </section>

      {videoVisible ? (
        <section id='overview-video'>
          <div className='row justify-center'>
            <div className='col w100'>
              <div className='responsive-video'>
                <iframe
                  src="https://www.youtube.com/embed/nr5d1R92xm4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
          <div className='row justify-center align-center'>
            <div className='col'>
              <a
                className='button-link button-link-brand mt'
                onClick={() => { setVideoVisible(false) }}
              >
                Close video
              </a>
            </div>
          </div>
        </section>
      ) : null}

      <section className='well callout'>
        <div className='row equal reverse align-center'>
          <div className='col'>
            <div className="inner image">
              <img src="/img/covid-canatrace.jpg" alt="Canatrace" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>Daily driver screening</h2>
              <p>Trexity has partnered with <a href="https://canatrace.com/">CANATRACE</a> to stay up to date on screening protocols. A driver must complete a COVID-19 symptoms screening to drive for the day.</p>
              <a href='https://canatrace.com/' className='cta-link' target='_blank' rel='noreferrer'>Learn about CANATRACE <i className="material-icons">keyboard_arrow_right</i></a>
            </div>
          </div>
        </div>
      </section>

      <section className='well callout'>
        <div className='row equal align-center'>
          <div className='col'>
            <div className="inner">
              <h2>Keeping us all safe</h2>
              <p>Drivers have access to safety kits which include face masks, hand sanitizer, and disposal gloves. They are required to wear face coverings at all times and adhere to safe, contactless delivery protocols.</p>
              <a className='button-link button-link-brand' style={{ marginBottom: 0 }} href='https://docs.google.com/forms/d/1c9s8W80IryVBVu8db0rVCS0NUiwjzrtIzpubzuf2Lf8/' target='_blank' rel='noreferrer'>Request a kit</a>
            </div>
          </div>
          <div className='col'>
            <div className="inner image">
              <img src="/img/covid-ppe.jpg" alt="PPE kit" />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row justify-center'>
          <div className='col w75 text-center'>
            <div className="inner">
              <h2>COVID-19 Ontario guidelines</h2>
              <p>We trust the experts to keep us safe. Learn about the public health and workplace safety measures for each zone under the colour-coded response framework.</p>
              <a className='button-link button-link-brand' target='_blank' rel='noreferrer' href='https://www.ontario.ca/page/covid-19-response-framework-keeping-ontario-safe-and-open'>Ontario guidelines</a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
